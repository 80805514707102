import restaurant from "./images/alelino.jpg";

import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/fon3.jpg";
import imgheader from "./images/fon3.jpg";
import imgheadermiini from "./images/12345.jpg";
import imgheadermiinimb from "./images/123.jpg";
import logo from './images/clipart1.png'



const data = {
    introData: [{
        copilul: "Robert",
        familia: "fam. Spînu",
        logo: logo,
        tata: "Mihai",
        mama: "Svetlana",
        data: "14 August 2022",
        data_confirmare: "",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "mikeltduk@gmail.com", 
       tel: "+373 797 31 143",
       phone: "tel:+37379731143",
       viber: "viber://chat?number=%2B37379731143",
       whatsapp: "https://wa.me/+447771064488",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Alelino",
            data: "14 august 2022, duminică, ora 16:00",
            adress: "str. Alexandru cel Bun 8, Ciorescu, Moldova",
            harta: "https://goo.gl/maps/3oVgWXCoNbwKBhEN6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10857.498329094578!2d28.8941847!3d47.130927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc9f28dd01a7b4328!2sRestaurant%20Alelino!5e0!3m2!1sro!2s!4v1659767989657!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;